import React, { useState, useEffect } from "react";
import TableLive from "../../components/tableLive";
import { useParams } from "react-router-dom";
import queryHttp from "../../services/queryService";
import { hourPretty } from "../../helpers/hourPretty";

export default function Live() {
  const [data, setData] = useState([]);
  const [nowProgram, setNowProgram] = useState([]);
  const [emitions, setEmitions] = useState([]);
  const [colorChannel, setColorChannel] = useState("#0000");

  let { web } = useParams();
  console.log(web);

  const getData = async () => {
    await queryHttp
      .getEmitionsChannel({
        name: web,
      })
      .then((response) => {
        setData(response.data.data);
        setNowProgram(
          (response.data.data.now.program &&
            response.data.data.now.program.name) ||
            "Sin Programa"
        );
        setEmitions(response.data.data.emitions);
        setColorChannel(response.data.data.color);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  console.log(data);
  console.log(nowProgram);
  console.log(emitions);

  const liveProgram = String(nowProgram)
    .toLowerCase()
    .replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase());

  const emitionsList = emitions.map((emition) => {
    return {
      time: `${hourPretty({ number: emition.time_start })} - ${hourPretty({
        number: emition.time_end,
      })}`,
      program: String(emition.program.name)
        .toLowerCase()
        .replace(/(^\w{1})|(\s+\w{1})/g, (letra) => letra.toUpperCase()),
      host: emition.program.host,
    };
  });

  console.log(emitionsList);

  return (
    <div>
      <h1> Tabla </h1>
      <TableLive
        emitions={emitionsList}
        nowProgram={liveProgram}
        colorChannel={colorChannel}
      />
    </div>
  );
}
