import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ChannelHttp from "../../../services/channelService";
import { Buttons } from "./form";

const text = "Eliminar";

export default function Delete({ row, refreshFunction }) {
  const [open, setOpen] = useState(false);
  const [data_send] = useState({ ...row });
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    ChannelHttp.remove(data_send._id)
      .then((response) => {
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        console.log(error);
      });
  };

  return (
    <Box>
      <IconButton onClick={handleClickOpen}>
        <Tooltip title="Delete">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
      <Dialog maxWidth={"xs"} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Eliminar Canal</DialogTitle>
        <DialogContent>
          <Typography>
            ¿Está seguro que desea eliminar el programa{" "}
            <strong>{data_send.name}</strong>?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Buttons
            handleAction={handleDelete}
            handleClose={handleClose}
            text={text}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
