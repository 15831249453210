import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import XLSX from "xlsx";
import { TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import DayHttp from "../../services/dayService";
import Alert from "../alert/index";
import DialogPrograms from "./dialog";
import { ThemeBenetworks } from "../theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function UploadExcel({ daysfiltrado, refreshFunction }) {
  const [dialog, setDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [loading, setLoading] = useState(false);
  const [cellRange, setCellRange] = useState("");
  const [button, setButton] = useState({
    data: [],
  });

  const handleDialog = () => {
    setDialog(!dialog);
  };
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const [data, setData] = React.useState([]);

  const handleRange = (e) => {
    setCellRange(e.target.value);
  };

  const handleFile = async (e) => {
    try {
      const file = e.target.files[0];
      const data = await file.arrayBuffer();
      const workbook = XLSX.read(data);

      var wsnames = workbook.SheetNames;

      const headers = [
        "HORA",
        "Lunes",
        "Martes",
        "Miércoles",
        "Jueves",
        "Viernes",
        "Sábado",
        "Domingo",
      ];

      let info = XLSX.utils.sheet_to_json(workbook.Sheets[wsnames[0]], {
        range: cellRange,
        header: headers,
      });

      let arrayData = [];

      info.map((item, index) => {
        const newItem = {};
        headers.forEach((key) => {
          newItem[key] = item[key] || arrayData[index - 1][key];
        });
        arrayData.push(newItem);
        return newItem;
      });

      let arrayDays = {};

      arrayData.map((item) => {
        Object.keys(item).forEach((key) => {
          let day = daysfiltrado.find((d) => d.name === String(key));
          if (key !== "HORA") {
            const hora = String(item.HORA).toLowerCase().split("a");

            const tempItem = {
              programa: item[key],
              hora_inicial: String(hora[0]).replace(/\s+/g, ""),
              hora_final: String(hora[1]).replace(/\s+/g, ""),
            };
            arrayDays[String(day._id)] = [
              ...(arrayDays[String(day._id)] || []),
              tempItem,
            ];
          }
        });
      });

      arrayDays = Object.keys(arrayDays).map((key) => {
        return {
          _id: key,
          data: arrayDays[key],
        };
      });

      setData(arrayDays);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    DayHttp.SaveEmitionsBulk(data)
      .then(async (response) => {
        // throw { message: "test error" };
        setSnack({
          open: true,
          message: response.data.message,
        });
        setDialog(response.data.data ? true : false);
        setButton({
          data: response.data.data ? response.data.data : [],
        });
        await refreshFunction();
        setLoading(false);
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        console.log(error);
      });
    setTimeout(() => {
      setSnack({
        open: false,
        message: "",
      });
    }, 6000);
  };

  return (
    <div>
      <ThemeBenetworks>
        <Button variant="contained" onClick={handleOpen}>
          Subir Excel
        </Button>
        <DialogPrograms
          data={button.data}
          handleDialog={handleDialog}
          dialog={dialog}
        />
        {snack.open && (
          <Alert message={snack.message} Popen={snack.open} type={snack.type} />
        )}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 3 }}
            >
              Selecciona un archivo
            </Typography>
            <TextField
              name="range"
              id="outlined-basic"
              label="Rango de Celdas (Ejemplo: A1:G100)"
              variant="outlined"
              onChange={(e) => handleRange(e)}
              fullWidth
              sx={{ mb: 3 }}
            />
            <TextField
              type={"file"}
              onChange={(e) => handleFile(e)}
            ></TextField>
            <Button
              variant="outlined"
              sx={{ mt: 3, mr: 2 }}
              onClick={handleClose}
            >
              Cerrar
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ mt: 3 }}
              onClick={() => handleSubmit()}
              loading={loading}
            >
              Guardar
            </LoadingButton>
          </Box>
        </Modal>
      </ThemeBenetworks>
    </div>
  );
}
