import jwt_decode from "jwt-decode";

const login = (token, expiration) => {
  setToken(token, expiration);
};

const logout = () => {
  localStorage.removeItem("token_radioformula_admin");
};

const setToken = (token, expiration) => {
  let expirationDate = new Date(new Date().getTime() + 60000 * (expiration / 60));
  let newValue = {
    value: token,
    expirationDate: expirationDate.toISOString(),
  };
  localStorage.setItem("token_radioformula_admin", JSON.stringify(newValue));
};

const getToken = () => {
  let stringValue = localStorage.getItem("token_radioformula_admin");
  if (stringValue !== null) {
    let value = JSON.parse(stringValue);
    let expirationDate = new Date(value.expirationDate);
    if (expirationDate > new Date()) {
      return value.value;
    } else {
      logout();
    }
  }
  return "";
};

const getCurrentUserId = () => {
  try {
    if (getToken() !== "") {
      var decoded = jwt_decode(getToken());
      return decoded.userId || "";
    }

    return "";
  } catch (err) {
    console.log("error", err);
    return "";
  }
};

const authService = {
  login,
  logout,
  getToken,
  getCurrentUserId,
};

export default authService;
