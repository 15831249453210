import React from "react";
import Switch from "@mui/material/Switch";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeBenetworks } from "../../../components/theme";
import UploadImage from "../../../components/uploadImage";
import getBase64Image from "../../../helpers/imageBase64";

export default function Form({ data_send, setData_send }) {
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleProgramImage = async (e) => {
    setData_send({
      ...data_send,
      programImage: await getBase64Image(e.target.files[0]),
    });
  };

  const handleHostImage = async (e) => {
    setData_send({
      ...data_send,
      hostImage: await getBase64Image(e.target.files[0]),
    });
  };

  return (
    <Box>
      <ThemeBenetworks>
        <FormControl fullWidth>
          <Grid container spacing={2}>
            <Grid item xs={10}>
              <TextField
                name="name"
                id="outlined-basic"
                label="Nombre"
                fullWidth
                defaultValue={data_send.name}
                onChange={({ target }) =>
                  setData_send({ ...data_send, name: target.value })
                }
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={2}>
              <UploadImage
                image={data_send.programImage}
                handleImage={handleProgramImage}
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                name="host"
                id="outlined-basic"
                label="Conductor"
                fullWidth
                defaultValue={data_send.host}
                onChange={({ target }) =>
                  setData_send({ ...data_send, host: target.value })
                }
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={2}>
              <UploadImage
                image={data_send.hostImage}
                handleImage={handleHostImage}
              />
            </Grid>
          </Grid>
          <TextField
            name="description"
            id="outlined-multiline-static"
            label="Descripción"
            multiline
            rows={4}
            fullWidth
            defaultValue={data_send.description}
            onChange={({ target }) =>
              setData_send({ ...data_send, description: target.value })
            }
            sx={{ mt: 3 }}
          />
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="flex-start"
            sx={{ mt: 2 }}
            onChange={({ target }) =>
              setData_send({ ...data_send, active: target.checked })
            }
          >
            <Typography color="text.secondary">Activo *</Typography>
            <Switch {...label} defaultChecked={data_send.active} />
          </Stack>
        </FormControl>
      </ThemeBenetworks>
    </Box>
  );
}

const Buttons = ({ handleAction, handleClose, text }) => {
  const action = async () => {
    await handleAction();
  };
  return (
    <Box sx={{ mb: 2, mr: 2 }}>
      <ThemeBenetworks>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => action()}>
              {text}
            </Button>
          </Grid>
        </Grid>
      </ThemeBenetworks>
    </Box>
  );
};

export { Buttons };
