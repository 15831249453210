import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Alert from "../../../components/alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Title = "Eliminar emisión";

const ButtonForm = {
  text: "Eliminar",
  icon: <EditOutlinedIcon />,
  textCancel: "Cancelar",
};

export default function DeleteButtonModal({ program, deleteProgram, position }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "success",
  });

  const handleEdit = () => {
    deleteProgram(position);
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <Tooltip title="Eliminar">
          <DeleteIcon />
        </Tooltip>
      </IconButton>
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack spacing={3}>
              <Typography gutterBottom variant="h5" component="div" sx={{ mb: 3 }}>
                {Title}
              </Typography>
              Estas seguro de querer eliminar la emisión
              <Box sx={{ display: "flex" }}>
                <Button
                  size="small"
                  color="secondary"
                  onClick={() => handleClose()}
                  variant="contained"
                  sx={{ ml: 27, mt: 1 }}
                >
                  {ButtonForm.textCancel}
                </Button>
                <Button
                  size="small"
                  color="secondary"
                  onClick={handleEdit}
                  loadingPosition="start"
                  startIcon={ButtonForm.icon}
                  variant="contained"
                  sx={{ ml: "auto", mt: 1 }}
                >
                  {ButtonForm.text}
                </Button>
              </Box>
            </Stack>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
