import React, { useState } from "react";
import { Container, Avatar, Typography, TextField, Button, Alert, Snackbar } from "@mui/material";
import HttpsIcon from "@mui/icons-material/Https";
import authService from "../../services/authService";
import authAxios from "../../services/authAxios";
import { useNavigate } from "react-router-dom";
import { ThemeBenetworks } from "../../components/theme";

export default function Login() {
  let navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });

  const onSubmit = (e) => {
    e.preventDefault();

    authAxios
      .post("auth/login", formData)
      .then((res) => {
        if (res.status === 200) {
          authService.login(res.data.access_token, res.data.expiresIn);
          navigate("/", { replace: true });
          window.location.reload();
          return true;
        }
      })
      .catch((error) => {
        setMessage(
          error.response ? error.response.data.message : error.message || "Ocurrió un error"
        );
        handleOpen();
      });
  };
  // const res = await authService.login(formData)

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("Error");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ThemeBenetworks>
      <Container elevation={10} maxWidth="xs" sx={{ height: "100vh", display: "flex" }}>
        <div
          style={{
            margin: "auto",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
              {message}
            </Alert>
          </Snackbar>

          <Avatar style={{ margin: "1rem", backgroundColor: "black" }}>
            <HttpsIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Bienvenido a Benetworks
          </Typography>
          <form
            onSubmit={onSubmit}
            style={{ marginTop: "1rem", width: "100%", borderColor: "black" }}
          >
            <TextField
              fullWidth
              autoFocus
              color="primary"
              margin="normal"
              variant="outlined"
              label="Correo electrónico"
              name={"email"}
              value={formData.email}
              onChange={handleChange}
            />

            <TextField
              sx={{ marginBottom: "30px" }}
              fullWidth
              type="password"
              color="primary"
              margin="normal"
              variant="outlined"
              label="Contraseña"
              name={"password"}
              value={formData.password}
              onChange={handleChange}
            />
            <Button
              sx={{
                margin: "3px 0px 2px",
                borderRadius: "5px",
                boxShadow: "15px",
              }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Iniciar Sesión
            </Button>
          </form>
        </div>
      </Container>
    </ThemeBenetworks>
  );
}
