import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "../../routes/live/style.css";

export default function TableLive({ emitions, nowProgram, colorChannel }) {
  console.log(emitions);
  console.log(nowProgram);
  console.log(colorChannel);

  return (
    <div>
      <Box>
        <table className="tableLive">
          <caption
            className="captionTable"
            style={{
              backgroundColor: colorChannel,
            }}
          >
            En vivo
            <div className="divSpan">
              <span className="spanFont"> {nowProgram}</span>
            </div>
          </caption>

          <thead>
            <tr className="liveFont"> NA </tr>
            <tr
              className="trTable"
              style={{
                backgroundColor: colorChannel,
              }}
            >
              <th className="thTable">Horario</th>
              <th className="thTable">Nombre del Programa</th>
              <th className="thTable">Conductor</th>
            </tr>
          </thead>
          <tbody>
            {emitions.map((emition) => {
              return (
                <tr>
                  <td className="liveFont">{emition.time}</td>
                  <td className="liveFont">{emition.program}</td>
                  <td className="liveFont">{emition.host}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
    </div>
  );
}
