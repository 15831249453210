export const hourPretty = ({ number }) => {
  let fullHour = "";
  if (Number.isInteger(number)) {
    number = number.toString();
    fullHour = `${number.length === 1 ? `0${number}` : number}:00`;
  } else {
    number = number.toString();
    const split = number.split(".");
    fullHour = `${split[0].length === 1 ? `0${split[0]}` : split[0]}:${
      split[1].length === 1 ? `${split[1]}0` : split[1]
    }`;
  }
  fullHour += "hrs";
  return fullHour;
};
