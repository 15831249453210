import { PhotoCamera } from "@mui/icons-material";
import { Avatar, IconButton, Stack } from "@mui/material";

export default function UploadImage({ image, handleImage }) {
  return (
    <div>
      <Stack>
        <IconButton
          color="primary"
          aria-label="upload picture"
          component="label"
        >
          <input hidden accept="image/*" type="file" onChange={handleImage} />
          {image === "" || image === undefined ? (
            <PhotoCamera />
          ) : (
            <Avatar alt="Remy Sharp" sx={{ width: 56, height: 56 }}>
              <img width={"100%"} src={image} alt="programImage" />
            </Avatar>
          )}
        </IconButton>
      </Stack>
    </div>
  );
}
