import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ProgramsHTTP from "../../services/programService";
import Alert from "../alert";

export default function DialogPrograms({ data, handleDialog, dialog }) {
  const [open, setOpen] = React.useState(dialog);
  const [scroll, setScroll] = React.useState("paper");
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  useEffect(() => {
    setOpen(dialog);
  }, [dialog]);

  const handleClose = () => {
    handleDialog();
    setOpen(false);
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const objectData = data.map((item) => {
    const tempItem = {
      name: item,
      programImage: "",
      host: "N/A",
      hostImage: "",
      active: true,
    };
    return tempItem;
  });

  const handleSaveProgramsBulk = () => {
    ProgramsHTTP.saveBulk(objectData)
      .then((response) => {
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        // refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        console.log(error);
      });
  };

  return (
    <div>
      {snack.open && (
        <Alert Popen={snack.open} message={snack.message} type={snack.type} />
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Programas faltantes</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {data}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
          <Button onClick={handleSaveProgramsBulk}>
            Agregar programas faltantes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
