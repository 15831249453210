import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./form";
import { Box, IconButton, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import channelHttp from "../../../services/channelService";
import { Buttons } from "./form";
import Alert from "../../../components/alert";

const text = "Editar";

export default function Edit({ row, refreshFunction }) {
  const [open, setOpen] = React.useState(false);
  const [data_send, setData_send] = React.useState({ ...row });
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    channelHttp
      .update(data_send._id, {
        name: data_send.name,
        description: data_send.description,
        url: data_send.url,
        isLive: data_send.isLive,
        oneSignal: data_send.oneSignal,
        active: data_send.active,
      })
      .then((response) => {
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
      });
  };

  return (
    <Box>
      <IconButton onClick={handleClickOpen}>
        <Tooltip title="Editar">
          <EditIcon />
        </Tooltip>
      </IconButton>
      {snack.open && (
        <Alert Popen={snack.open} message={snack.message} type={snack.type} />
      )}
      <Dialog maxWidth={"sm"} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Editar Canal</DialogTitle>
        <DialogContent>
          <Form data_send={data_send} setData_send={setData_send} />
        </DialogContent>
        <DialogActions>
          <Buttons
            handleAction={handleEdit}
            handleClose={handleClose}
            text={text}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
