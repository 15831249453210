import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import UserHttp from "../../services/userService";
import AddButton from "./actions/add";
import EditButton from "./actions/edit";
import DeleteButton from "./actions/delete";
import { ThemeBenetworks } from "../../components/theme";
import tokenExpired from "../../helpers/tokenExpired";
import authService from "../../services/authService";

export default function Users() {
  const [users, setUsers] = useState([]);

  const getData = async () => {
    UserHttp.getAll({ token: authService.getToken(), withDeleted: true })
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { label: "Nombre", id: "username" },
    { label: "Email", id: "email" },
    { label: "Acciones", buttons: [EditButton, DeleteButton] },
  ];

  return (
    <div>
      <ThemeBenetworks>
        <AddButton refreshFunction={getData} />
      </ThemeBenetworks>
      <Table data={users} columns={columns} refreshFunction={getData} />
    </div>
  );
}
