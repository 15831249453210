import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Alert from "../../../components/alert";
import Form from "./form";
import { ThemeBenetworks } from "../../../components/theme";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Title = "Agregar Emisiones";

const ButtonForm = {
  text: "Guardar",
  icon: <SaveIcon />,
  textCancel: "Cancelar",
};

export default function Add({ addProgram }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const handleSave = async ({ formData }) => {
    addProgram(formData);
    handleClose();
  };

  return (
    <div>
      <Box sx={{ display: "flex" }}>
        <ThemeBenetworks>
          <Button variant="contained" sx={{ ml: "auto" }} onClick={handleOpen}>
            <AddIcon />
            Agregar
          </Button>
        </ThemeBenetworks>
        {snack.open && (
          <Alert message={snack.message} Popen={snack.open} type={snack.type} />
        )}
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Form
              data={null}
              handleAction={handleSave}
              title={Title}
              ButtonForm={ButtonForm}
              handleClose={handleClose}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
