export const dateToDoubletime = (newTime) => {
  const stringTime = new Date(newTime).toTimeString().slice(0, 5).split(":");
  return +`${stringTime[0]}.${stringTime[1]} `;
};

export const stringPrettyToDateTime = (timeDouble) => {
  const stringTime = String(timeDouble).slice(0, 5).split(":");
  const date = new Date().setHours(+stringTime[0], +stringTime[1]);
  return new Date(date);
};
