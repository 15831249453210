import React, { useState, useEffect } from "react";
import Table from "../../components/table";
import ChannelHttp from "../../services/channelService";
import AddButton from "./actions/add";
import EditButton from "./actions/edit";
import DeleteButton from "./actions/delete";
import { ThemeBenetworks } from "../../components/theme";
import tokenExpired from "../../helpers/tokenExpired";

export default function Channels() {
  const [channels, setChannels] = useState([]);

  const getData = async () => {
    ChannelHttp.getAll()
      .then((response) => {
        setChannels(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    { label: "Nombre del canal", id: "name" },
    { label: "Descripción", id: "description" },
    { label: "Acciones", buttons: [EditButton, DeleteButton] },
  ];

  return (
    <div>
      <ThemeBenetworks>
        <AddButton refreshFunction={getData} />
      </ThemeBenetworks>
      <Table data={channels} columns={columns} refreshFunction={getData} />
    </div>
  );
}
