import { useEffect, useState } from "react";
import Table from "../../components/table";
import ProgramHttp from "../../services/programService";
import AddButton from "./actions/add";
import EditButton from "./actions/edit";
import DeleteButton from "./actions/delete";
import { ThemeBenetworks } from "../../components/theme";
import tokenExpired from "../../helpers/tokenExpired";
import authService from "../../services/authService";
import AvatarCustom from "../../components/avatar";

export default function Programs() {
  const [programs, setPrograms] = useState([]);

  const getData = async () => {
    ProgramHttp.getAll({ token: authService.getToken() })
      .then((response) => {
        setPrograms(response.data.data);
      })
      .catch((error) => {
        tokenExpired(error);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const defaultImage = "placecholder-default.jpg";

  const columns = [
    {
      label: "Nombre del programa",
      id: "name",
      avatar: (image) =>
        image.programImage ? (
          <AvatarCustom
            sx={{
              mr: 2,
            }}
            rowData={image.programImage}
          />
        ) : (
          <AvatarCustom
            sx={{
              mr: 2,
            }}
            rowData={defaultImage}
          />
        ),
    },
    { label: "Conductor", id: "host" },
    { label: "Acciones", buttons: [EditButton, DeleteButton] },
  ];

  return (
    <div>
      <ThemeBenetworks>
        <AddButton refreshFunction={getData} />
      </ThemeBenetworks>
      <Table data={programs} columns={columns} refreshFunction={getData} />
    </div>
  );
}
