import http from "./index";

const getAll = () => {
  return http.get("/channels");
};

const getOne = (id) => {
  return http.get(`/channels/${id}`);
};

const create = (data) => {
  return http.post("/channels", data);
};

const update = (id, data) => {
  return http.put(`/channels/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/channels/${id}`);
};

const channelService = {
  getAll,
  getOne,
  create,
  update,
  remove,
};

export default channelService;
