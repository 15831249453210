import http from "./index";

const getAll = () => {
  return http.get("/programs");
};

const getOne = (id) => {
  return http.get(`/programs/${id}`);
};

const create = (data) => {
  return http.post("/programs", data);
};

const update = (id, data) => {
  return http.put(`/programs/${id}`, data);
};

const remove = (id) => {
  return http.delete(`/programs/${id}`);
};

const saveBulk = (data) => {
  return http.post("/programs/save-bulk", data);
};

const programService = {
  getAll,
  getOne,
  create,
  update,
  remove,
  saveBulk,
};

export default programService;
