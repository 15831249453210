import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { ThemeBenetworks } from "../../../components/theme";

export default function Form({ data_send, setData_send }) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box>
      <ThemeBenetworks>
        <FormControl fullWidth>
          <TextField
            name="name"
            label="Nombre"
            variant="outlined"
            defaultValue={data_send.username}
            onChange={({ target }) => setData_send({ ...data_send, username: target.value })}
            sx={{ mt: 2 }}
          />
          <TextField
            name="email"
            label="Email"
            variant="outlined"
            defaultValue={data_send.email}
            onChange={({ target }) => setData_send({ ...data_send, email: target.value })}
            sx={{ mt: 2 }}
          />
          <FormControl sx={{ mt: 2 }}>
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
              name="password"
              id="outlined-adornment-password"
              onChange={({ target }) => setData_send({ ...data_send, password: target.value })}
              type={showPassword ? "text" : "password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </FormControl>
      </ThemeBenetworks>
    </Box>
  );
}

const Buttons = ({ handleAction, handleClose, text }) => {
  const action = async () => {
    await handleAction();
  };
  return (
    <Box sx={{ mb: 2, mr: 2 }}>
      <ThemeBenetworks>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => action()}>
              {text}
            </Button>
          </Grid>
        </Grid>
      </ThemeBenetworks>
    </Box>
  );
};

export { Buttons };
