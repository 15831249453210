import http from "./index";

const getEmitionsChannel = ({name}) => {
    return http.get("/query", 
        {
            params: {
                name: name
            }
        }
    );
    };

const queryService = {
    getEmitionsChannel,
}

export default queryService;