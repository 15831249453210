import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@mui/material";
import Alert from "../alert";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import EditButton from "../../routes/schedules/actions/edit";
import DeleteButton from "../../routes/schedules/actions/delete";

export default function ProgramsTable({
  programs,
  setPrograms,
  programsData,
  validateErrors,
  deleteProgram,
  updateProgram,
}) {
  const [progamsArray, setProgramsArray] = useState([]);
  const [arrayPrograms, setArrayPrograms] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "warning",
  });

  useEffect(() => {
    setArrayPrograms(programsData);
  }, [programsData]);

  useEffect(() => {
    setProgramsArray(programs);
  }, [programs]);

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }

    const items = [...progamsArray];
    const [removed] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, removed);
    setProgramsArray(items);
    setPrograms(items);
  };

  const dayPretty = ({ number }) => {
    let fullHour = "";
    if (Number.isInteger(number)) {
      number = number.toString();
      fullHour = `${number.length === 1 ? `0${number}` : number}:00`;
    } else {
      number = number.toString();
      const split = number.split(".");
      fullHour = `${split[0].length === 1 ? `0${split[0]}` : split[0]}:${
        split[1].length === 1 ? `${split[1]}0` : split[1]
      }`;
    }

    fullHour += "hrs";
    return fullHour;
  };

  const getProgramName = (program_id) => {
    const filter = arrayPrograms.filter((program) => program._id === program_id);
    return (filter[0] && filter[0].name) || "Desconocido";
  };

  return (
    <Card>
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={"warning"} />}
      <CardContent>
        {progamsArray !== undefined && progamsArray.length !== 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppabl">
              {(droppableProvided) => (
                <div
                  className="droppabl"
                  {...droppableProvided.droppableProps}
                  ref={droppableProvided.innerRef}
                >
                  {progamsArray.map((program, index) => (
                    <Draggable
                      key={`${program.program_id}-${index}`}
                      draggableId={`${program.program_id}-${index}`}
                      index={index}
                    >
                      {(draggableProvider) => (
                        <Card
                          sx={{ m: 2, borderRadius: 1, boxShadow: 10 }}
                          {...draggableProvider.draggableProps}
                          ref={draggableProvider.innerRef}
                          {...draggableProvider.dragHandleProps}
                        >
                          <CardContent key={index}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={8}>
                                <Grid container spacing={2}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant="h6"
                                      color={"gb(57, 56, 56)"}
                                      sx={{ fontSize: 19 }}
                                    >
                                      {getProgramName(program.program_id)}
                                    </Typography>
                                    <Typography variant="body1" sx={{ fontSize: 15 }}>
                                      {dayPretty({ number: program.time_start })} -{" "}
                                      {dayPretty({ number: program.time_end })}{" "}
                                    </Typography>
                                    {validateErrors.length > 0 && (
                                      <Typography
                                        variant="body1"
                                        sx={{ fontSize: 14, color: "red" }}
                                      >
                                        {validateErrors.map((obj) => {
                                          if (obj.position === index) {
                                            return (
                                              <ul>
                                                {obj.error_times && <li>{obj.error_times}</li>}
                                                {obj.timeEndPreviusEmition && (
                                                  <li>{obj.timeEndPreviusEmition}</li>
                                                )}
                                              </ul>
                                            );
                                          }
                                        })}
                                      </Typography>
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box sx={{ display: "flex", justifyContent: "end" }}>
                                  <EditButton
                                    program={program}
                                    editProgram={updateProgram}
                                    position={index}
                                  />
                                  <DeleteButton
                                    program={program}
                                    deleteProgram={deleteProgram}
                                    position={index}
                                  />
                                </Box>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {droppableProvided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <div>No hay emisiones registradas</div>
        )}
      </CardContent>
    </Card>
  );
}
