import { useEffect, useState } from "react";
import Select from "../../components/select";
import ProgramsTable from "../../components/programsTable";
import { Button, Card, CardContent, Grid } from "@mui/material";
import AddButton from "./actions/add";
import Alert from "../../components/alert";
import ProgramHttp from "../../services/programService";
import DayHttp from "../../services/dayService";
import { ThemeBenetworks } from "../../components/theme";

export default function Schedule() {
  const [programs, setPrograms] = useState([]);
  const [id_dia, setIdDia] = useState("");
  const [day, setDay] = useState(null);
  const [programsData, setProgramsData] = useState([]);
  const [validateErrors, setValidateErrors] = useState([]);
  const [validate, setValidate] = useState(false);
  const [refreshDays, setRefreshDays] = useState(null);
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const getPrograms = async ({ id_day, emitions }) => {
    setIdDia(id_day);
    setPrograms(emitions);
  };

  useEffect(() => {
    async function fetchData() {
      await getProgramsData();
    }
    fetchData();
  }, []);

  const getProgramsData = async () => {
    setRefreshDays(Date.now());
    const {
      data: { data: programs },
    } = await ProgramHttp.getAll();
    setProgramsData(programs);
  };

  const showAlert = (message) => {
    setSnack({ open: true, message: message });
    setTimeout(() => {
      setSnack({ open: false, message: "" });
    }, 6000);
  };

  const establecerDia = ({ day }) => {
    // console.log("establecerDia", day);
    setDay(day);
  };

  const addProgram = (data) => {
    console.log(programs);
    setPrograms((current) => [...current, data]);
    setValidate(false);
    showAlert("Emision agregada");
  };

  const deleteProgram = (position) => {
    setPrograms((current) => current.filter((cur, index) => index !== position));
    setValidate(false);
    showAlert("Emision eliminada");
  };

  const updateProgram = (data, position) => {
    setPrograms((current) =>
      current.map((cur, index) => {
        if (index === position) {
          return {
            ...cur,
            program_id: data.program_id,
            time_end: data.time_end,
            time_start: data.time_start,
          };
        }
        return cur;
      })
    );
    setValidate(false);
    showAlert("Emision actualizada");
  };

  const handleSaveEmitions = async () => {
    DayHttp.saveEmitions(day._id, programs)
      .then((response) => {
        showAlert(response.data.message);
        setRefreshDays(Date.now());
      })
      .catch((error) => {
        showAlert(
          error.response ? error.response.data.message : error.message || "Ocurrió un error"
        );
        console.log(error);
      });
  };

  useEffect(() => {
    validateSchedule();
  }, [programs]);

  const validateSchedule = () => {
    let errors = [];
    for (let index = 0; index < programs.length; index++) {
      let error = 0;
      let errorsTemp = {};
      //si es menor o igual 23.59 y si es mayor o igual a 0 // si es factible la existnecia de esta emision
      /* if (programs[index].time_start <= 23.59 && programs[index].time_end >= 0) {
        console.log("Si es factible")
      } else { */
      /* if(programs[index].time_end < programs[index].time_start) {
          errorsTemp.error_times = "La hora de finalizacion de la emisión es menor a la hora de inicio de la misma"
          error++
        }  */
      /* } */
      if (index !== 0) {
        if (programs[index - 1].time_end > programs[index].time_start) {
          errorsTemp.timeEndPreviusEmition =
            "La emisión anterior tiene una hora de finalización mayor a la hora de inicio de esta emisión";
          error++;
        }
      }
      if (error !== 0) {
        errorsTemp.position = index;
        errors.push(errorsTemp);
      }
    }
    setValidateErrors(errors);
    setValidate(errors.length === 0 ? true : false);
  };

  return (
    <div>
      <Card>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <div>
                <Select
                  getPrograms={getPrograms}
                  establecerDia={establecerDia}
                  refresh={refreshDays}
                  refreshFunction={getProgramsData}
                />
                {validate && (
                  <p>
                    <ThemeBenetworks>
                      <Button
                        variant="contained"
                        sx={{ ml: "auto" }}
                        onClick={() => handleSaveEmitions()}
                      >
                        Actualizar programación
                      </Button>
                    </ThemeBenetworks>
                  </p>
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={8}>
              {day !== null && <AddButton addProgram={addProgram} />}
              <ProgramsTable
                refreshFunction={getProgramsData}
                programs={programs}
                setPrograms={setPrograms}
                validateErrors={validateErrors}
                programsData={programsData}
                deleteProgram={deleteProgram}
                updateProgram={updateProgram}
                id_day={id_dia}
                day={day}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {snack.open && <Alert message={snack.message} Popen={snack.open} type={snack.type} />}
    </div>
  );
}
