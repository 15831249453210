import { Navigate, useLocation } from "react-router-dom";
import authService from "../../services/authService";

export function PrivateRoute(props) {
  const currentUserId = authService.getCurrentUserId();
  const location = useLocation();

  if (currentUserId === "") return <Navigate to="/login" state={{ from: location.pathname }} />;
  else return props.children;
}
