const getBase64Image = async (image) => {
  const prom = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async () => {
      try {
        resolve(reader.result);
      } catch (err) {
        reject(err);
      }
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(image);
  });

  // Wait for all promises to be resolved
  const fileInfos = await prom;

  // Profit
  return fileInfos;
};

export default getBase64Image;

export async function dataUrlToFile(dataUrl, fileName) {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, { type: "image/png" });
}

export const convertImage = (base64img) => {
  var img = document.createElement("img");
  img.src = base64img;

  return img;
};
