import { Avatar } from "@mui/material";

export default function AvatarCustom({ sx, rowData }) {
  console.log(rowData);
  return (
    <Avatar alt="Remy Sharp" sx={sx}>
      <img width={"100%"} src={rowData} alt="hostImage" />
    </Avatar>
  );
}
