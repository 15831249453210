import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {
  Box,
  Button,
  FormControl,
  Grid,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeBenetworks } from "../../../components/theme";

export default function Form({ data_send, setData_send }) {
  const [alignment, setAlignment] = useState(true);
  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
    setData_send({ ...data_send, isLive: newAlignment });
  };

  return (
    <Box>
      <ThemeBenetworks>
        <FormControl fullWidth>
          <Grid container spacing={2} columns={16}>
            <Grid item xs={8}>
              <TextField
                name="name"
                id="outlined-basic"
                label="Nombre"
                variant="outlined"
                fullWidth
                defaultValue={data_send.name}
                onChange={({ target }) =>
                  setData_send({ ...data_send, name: target.value })
                }
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="url"
                id="outlined-basic"
                label="Url"
                variant="outlined"
                fullWidth
                defaultValue={data_send.url}
                onChange={({ target }) =>
                  setData_send({ ...data_send, url: target.value })
                }
                sx={{ mt: 2 }}
              />
            </Grid>
            <Grid item xs={8}>
              <TextField
                name="description"
                id="outlined-multiline-static"
                label="Descripción"
                multiline
                rows={4}
                fullWidth
                defaultValue={data_send.description}
                onChange={({ target }) =>
                  setData_send({ ...data_send, description: target.value })
                }
                sx={{ mt: 1 }}
              />
            </Grid>
            <Grid item xs={8}>
              <ToggleButtonGroup
                color="primary"
                value={
                  data_send.isLive !== undefined ? data_send.isLive : alignment
                }
                exclusive
                onChange={handleChange}
                aria-label="Platform"
                sx={{ mt: 1 }}
                fullWidth
              >
                <ToggleButton value={true}>Canal</ToggleButton>
                <ToggleButton value={false}>Otro</ToggleButton>
              </ToggleButtonGroup>
              <TextField
                name="oneSignal"
                id="outlined-basic"
                label="One Signal "
                variant="outlined"
                fullWidth
                defaultValue={data_send.oneSignal}
                onChange={({ target }) =>
                  setData_send({ ...data_send, oneSignal: target.value })
                }
                sx={{ mt: 2.5 }}
              />
            </Grid>
            <Grid item xs={8}>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="flex-start"
                sx={{ mt: 1 }}
                onChange={({ target }) =>
                  setData_send({ ...data_send, active: target.checked })
                }
              >
                <Typography color="text.secondary">Activo *</Typography>
                <Switch {...label} defaultChecked={data_send.active} />
              </Stack>
            </Grid>
          </Grid>
        </FormControl>
      </ThemeBenetworks>
    </Box>
  );
}

const Buttons = ({ handleAction, handleClose, text }) => {
  const action = async () => {
    await handleAction();
  };
  return (
    <Box sx={{ mb: 2, mr: 2 }}>
      <ThemeBenetworks>
        <Grid container spacing={2}>
          <Grid item>
            <Button variant="outlined" onClick={() => handleClose()}>
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" onClick={() => action()}>
              {text}
            </Button>
          </Grid>
        </Grid>
      </ThemeBenetworks>
    </Box>
  );
};

export { Buttons };
