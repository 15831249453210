import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { Routes, Route, Outlet } from "react-router-dom";
import Drawer from "./components/drawer";
import Programs from "./routes/programs";
import Channels from "./routes/channels";
import Schedules from "./routes/schedules";
import Users from "./routes/users";
import { PrivateRoute } from "./components/privateRoute";
import Login from "./routes/auth";
import Logout from "./routes/auth/logout";
import Live from "./routes/live";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Layout />}>
        <Route index element={<Schedules />} />
        <Route path="programas" element={<Programs />} />
        <Route path="canales" element={<Channels />} />
        <Route path="usuarios" element={<Users />} />
        <Route path="logout" element={<Logout />} />
      </Route>
      <Route path="en-vivo/:web" element={<Live />} />
    </Routes>
  );
}

function Layout() {
  return (
    <PrivateRoute>
      <div>
        <Box sx={{ display: "flex" }}>
          <Drawer />
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Outlet />
          </Box>
        </Box>
      </div>
    </PrivateRoute>
  );
}

export default App;
