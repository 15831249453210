import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Form from "./form";
import { Box, Typography } from "@mui/material";
import { Buttons } from "./form";
import channelHttp from "../../../services/channelService";
import Alert from "../../../components/alert";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const text = "Guardar";

export default function Add({ refreshFunction }) {
  const [open, setOpen] = useState(false);
  const [data_send, setData_send] = useState({
    name: "",
    description: "",
    url: "",
    isLive: true,
    oneSignal: "",
    active: true,
  });
  const [snack, setSnack] = useState({
    open: false,
    message: "Error",
    type: "Success",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setData_send("");
  };

  const handleSave = () => {
    channelHttp
      .create(data_send)
      .then((response) => {
        setSnack({
          open: true,
          message: response.data.message,
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        handleClose();
        refreshFunction();
      })
      .catch((error) => {
        setSnack({
          open: true,
          message: error.response
            ? error.response.data.message
            : error.message || "Ocurrió un error",
          type: "error",
        });
        setTimeout(() => {
          setSnack({
            open: false,
            message: "",
          });
        }, 6000);
        console.log(error);
      });
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Typography variant="h4" color="rgb(64,63,63)">
        Canales
      </Typography>
      <Button variant="contained" sx={{ ml: "auto" }} onClick={handleClickOpen}>
        <AddCircleIcon sx={{ mr: 1 }} />
        Añadir
      </Button>
      {snack.open && (
        <Alert Popen={snack.open} message={snack.message} type={snack.type} />
      )}
      <Dialog maxWidth={"sm"} fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Añadir Canal</DialogTitle>
        <DialogContent>
          <Form data_send={data_send} setData_send={setData_send} />
        </DialogContent>
        <DialogActions>
          <Buttons
            handleAction={handleSave}
            handleClose={handleClose}
            text={text}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}
